import React, { useState, useContext } from "react";
import { AuthContext } from "src/context/Auth";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import PageLoading from "src/component/PageLoading";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 80,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    background: theme.palette.background.default,
  },
  content: {
    flex: "1 1 auto",
    height: "calc(100vh - 80px)",
    overflow: "auto",
    position: "relative",
    WebkitOverflowScrolling: "touch",
  },
}));

const DashboardLayout = ({ children }) => {
  const auth = useContext(AuthContext);
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      {Object.keys(auth.userData.role).length === 0 ? (
        <PageLoading />
      ) : (
        <>
          <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
          <NavBar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
          />
          <div className={classes.wrapper}>
            <div className={classes.contentContainer}>
              <div className={classes.content} id="main-scroll">
                {children}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
