/* eslint-disable no-use-before-define */
import React, { useEffect, useContext } from "react";
import { useLocation, matchPath } from "react-router-dom";
import { AuthContext } from "src/context/Auth";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";

import {
  FaTruckMoving,
  FaUserAlt,
  FaPhoneVolume,
  FaCreditCard,
  FaBookmark,
  FaFileInvoiceDollar,
  FaHammer,
  FaPlusCircle,
  FaLifeRing,
  FaTachometerAlt,
  FaIndustry,
  FaCity,
} from "react-icons/fa";
import NavItem from "./NavItem";

const sectionsSupplier = [
  {
    items: [
      {
        title: "Dashboard",
        icon: FaTachometerAlt,
        href: "/dashboard",
      },
      {
        title: "Quotes/ Bids",
        icon: FaFileInvoiceDollar,
        href: "/supplier/quotes-and-bids",
      },
      {
        title: "My Bookings",
        icon: FaBookmark,
        href: "/supplier/bookings",
      },
      {
        title: "Billing",
        icon: FaCreditCard,
        href: "/supplier/billing",
      },
      {
        title: "Active Thermal Plants",
        icon: FaIndustry,
        href: "/list-activethermal",
      },
      {
        title: "Crusher and Mining info",
        icon: FaCity,
        href: "/crusher-mining-info",
      },
      {
        title: "Contact Us",
        icon: FaPhoneVolume,
        href: "/contactus",
      },
    ],
  },
];

const sectionsCompany = [
  {
    items: [
      {
        title: "Dashboard",
        icon: FaTachometerAlt,
        href: "/dashboard",
      },
      {
        title: "Quotes",
        icon: FaFileInvoiceDollar,
        href: "/quote-list-client",
      },
      {
        title: "My Bookings",
        icon: FaBookmark,
        href: "/bookings-list-client",
      },
      {
        title: "Billing",
        icon: FaCreditCard,
        href: "/billing-list-client",
      },
      {
        title: "Create Site",
        icon: FaPlusCircle,
        href: "/new-site",
      },
      {
        title: "Create User",
        icon: FaUserAlt,
        href: "/create-user",
      },
      {
        title: "Active Thermal Plants",
        icon: FaIndustry,
        href: "/list-activethermal",
      },
      {
        title: "Crusher and Mining info",
        icon: FaCity,
        href: "/crusher-mining-info",
      },
      {
        title: "Contact Us",
        icon: FaPhoneVolume,
        href: "/contactus",
      },
    ],
  },
];
const sectionsCompany_MANAGER = [
  {
    items: [
      {
        title: "Quotes",
        icon: FaFileInvoiceDollar,
        href: "/quote-list-client",
      },
      {
        title: "My Bookings",
        icon: FaBookmark,
        href: "/bookings-list-client",
      },
      {
        title: "Billing",
        icon: FaCreditCard,
        href: "/billing-list-client",
      },
      {
        title: "Active Thermal Plants",
        icon: FaIndustry,
        href: "/list-activethermal",
      },
      {
        title: "Crusher and Mining info",
        icon: FaCity,
        href: "/crusher-mining-info",
      },
      {
        title: "Contact Us",
        icon: FaPhoneVolume,
        href: "/contactus",
      },
    ],
  },
];
const sectionsCompany_ENGG = [
  {
    items: [
      {
        title: "My Bookings",
        icon: FaBookmark,
        href: "/bookings-list-client",
      },
      {
        title: "Active Thermal Plants",
        icon: FaIndustry,
        href: "/list-activethermal",
      },
      {
        title: "Crusher and Mining info",
        icon: FaCity,
        href: "/crusher-mining-info",
      },
      {
        title: "Contact Us",
        icon: FaPhoneVolume,
        href: "/contactus",
      },
    ],
  },
];

const sectionsFleetOwner = [
  {
    items: [
      {
        title: "Dashboard",
        icon: FaTachometerAlt,
        href: "/dashboard",
      },
      {
        title: "Bids",
        icon: FaHammer,
        href: "/bids-list",
      },
      {
        title: "My Bookings",
        icon: FaBookmark,
        href: "/mybooking",
      },
      {
        title: "Billing",
        icon: FaCreditCard,
        href: "/billing-list-fleet",
      },
      {
        title: "Trucks",
        icon: FaTruckMoving,
        href: "/truck-list",
      },
      {
        title: "Drivers",
        icon: FaLifeRing,
        href: "/drivers-list",
      },
      {
        title: "Contact Us",
        icon: FaPhoneVolume,
        href: "/contactus",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: theme.palette.primary.main,
  },
  desktopDrawer: {
    width: 256,
    top: 80,
    height: "calc(100% - 80px)",
    background: theme.palette.primary.main,
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const auth = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const [sections, setSections] = React.useState([]);

  useEffect(() => {
    if (
      auth.userData.role.role === "COMPANY" ||
      auth.userData.role.role === "COMPANY_ADMIN"
    ) {
      setSections(sectionsCompany);
    } else if (auth.userData.role.role === "COMPANY_SITE_ENGG") {
      setSections(sectionsCompany_ENGG);
    } else if (auth.userData.role.role === "COMPANY_MANAGER") {
      setSections(sectionsCompany_MANAGER);
    } else if (auth.userData.role.role === "FLEET") {
      setSections(sectionsFleetOwner);
    } else if (auth.userData.role.role === "SUPPLIER") {
      setSections(sectionsSupplier);
    }
  }, [auth.userData]);

  const handleClose = () => {
    setOpen(false);
  };
  const DialogContent = withStyles((theme) => ({
    root: {
      height: 200,
      [theme.breakpoints.down("sm")]: {
        height: 150,
      },
      [theme.breakpoints.down("xs")]: {
        height: 150,
      },
    },
  }))(MuiDialogContent);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box py={4}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={"xs"}
      >
        <DialogContent>
          <Typography
            gutterBottom
            style={{
              paddingTop: "15%",
              textAlign: "center",
              fontSize: "22",
              fontWeight: "bold",
            }}
          >
            Your Requst for adding a driver has been submitted successfully.
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
