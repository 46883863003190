import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "src/context/Auth";

export function CompanyUserGuard(props) {
  const { children } = props;
  const auth = useContext(AuthContext);

  if (!auth.userLoggedIn) {
    return <Redirect to="/" />;
  }

  if (
    Object.keys(auth.userData.role).length !== 0 &&
    auth.userData.role.role !== "COMPANY" &&
    auth.userData.role.role !== "COMPANY_ADMIN"
  ) {
    return <Redirect to="/dashboard" />;
  }

  return <>{children}</>;
}

export function CompanyGuard(props) {
  const { children } = props;
  const auth = useContext(AuthContext);

  if (!auth.userLoggedIn) {
    return <Redirect to="/" />;
  }

  if (
    Object.keys(auth.userData.role).length !== 0 &&
    auth.userData.role.role !== "COMPANY" &&
    auth.userData.role.role !== "COMPANY_ADMIN" &&
    auth.userData.role.role !== "COMPANY_MANAGER" &&
    auth.userData.role.role !== "COMPANY_SITE_ENGG"
  ) {
    return <Redirect to="/dashboard" />;
  }

  return <>{children}</>;
}

export function SupplierGuard(props) {
  const { children } = props;
  const auth = useContext(AuthContext);

  if (!auth.userLoggedIn) {
    return <Redirect to="/" />;
  }

  if (
    Object.keys(auth.userData.role).length !== 0 &&
    auth.userData.role.role !== "SUPPLIER"
  ) {
    return <Redirect to="/dashboard" />;
  }

  return <>{children}</>;
}

export function FleetGuard(props) {
  const { children } = props;
  const auth = useContext(AuthContext);

  if (!auth.userLoggedIn) {
    return <Redirect to="/" />;
  }

  if (
    Object.keys(auth.userData.role).length !== 0 &&
    auth.userData.role.role !== "FLEET"
  ) {
    return <Redirect to="/dashboard" />;
  }

  return <>{children}</>;
}
