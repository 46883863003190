import React, { createContext, useState, useCallback, useEffect } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import ApiConfig from "src/config/APIConfig";
import useIsMountedRef from "src/component/useIsMountedRef";
export const AuthContext = createContext();

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
  } else {
    localStorage.removeItem("accessToken");
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("accessToken");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({ role: {} });
  const [siteDetails, setSiteDetails] = useState();
  const [NotificationCount, setNotificationCount] = useState(0);
  const [isLoadingProfile, serIsLoadingProfile] = useState(false);

  const isMountedRef = useIsMountedRef();

  const getMyAccount = useCallback(async () => {
    const accessToken = window.localStorage.getItem("accessToken");
    serIsLoadingProfile(true);
    try {
      const response = await axios.get(ApiConfig.getUserProfileDetails, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      if (isMountedRef.current) {
        if (response.data.status === 200) {
          setUserData(response.data.data.userDetails);
          setSiteDetails(response.data.data.siteDetails);
          setIsLogin(true);
          getNotifications(response.data.data.userDetails.role.role);
        } else {
          setUserData({ role: {} });
          setSiteDetails();
          setSession(null);
          setIsLogin(false);
        }
      }
      serIsLoadingProfile(false);
    } catch (err) {
      console.error(err.response);
      setUserData({ role: {} });
      setSiteDetails();
      setSession(null);
      setIsLogin(false);
      serIsLoadingProfile(false);
    }
  }, [isMountedRef]); //eslint-disable-line

  const getNotifications = useCallback(
    async (role) => {
      const accessToken = window.localStorage.getItem("accessToken");
      try {
        const response = await axios.get(ApiConfig.getNotificationData, {
          params: {
            role: role,
          },
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        });
        console.log(response.data);
        if (isMountedRef.current) {
          if (response.data.status !== 1618) {
          } else {
            setNotificationCount(response.data.data.length);
          }
          await axios.get(ApiConfig.readNotification, {
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
          });
        }
      } catch (err) {
        console.error(err.response);
      }
    },
    [isMountedRef]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const accessToken = window.localStorage.getItem("accessToken");
    const validToken = isValidToken(accessToken);
    if (validToken) {
      getMyAccount();
    } else {
      setUserData({ role: {} });
      setSession(null);
      setIsLogin(false);
    }
  }, [getMyAccount]);

  let data = {
    userLoggedIn: isLogin,
    userData,
    NotificationCount,
    siteDetails,
    isLoadingProfile,
    updateNotifications: (role) => getNotifications(role),
    updateNotificationCount: (data) => {
      setNotificationCount(data);
    },
    userLogIn: (type, data) => {
      if (type) {
        setSession(data);
        getMyAccount();
      } else {
        setSession(data);
        setIsLogin(type);
      }
    },
    getUserDetails: () => {
      return userData.userDetail;
    },
    updateUserDetails: () => {
      getMyAccount();
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
