import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AuthContext } from 'src/context/Auth';
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  IconButton,
  Badge,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SwipeableTemporaryDrawer from './RightDrawer';
import { useHistory } from 'react-router-dom';
import Logo from 'src/component/Logo';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
  toolbar: {
    height: 80,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const history = useHistory();
  const auth = useContext(AuthContext);

  const web = new WebSocket('wss://notification.porteight.io/notification');
  web.onopen = () => {
    const dataToSend = {
      userId: auth.userData.userId,
      notificationUserType: auth.userData.role.role,
    };
    web.send(JSON.stringify(dataToSend));
    web.onmessage = async (event) => {
      console.log('data', event);
      auth.updateNotifications(auth.userData.role.role);
    };
  };
  return (
    <>
      <Logo
        width="180"
        onClick={() => history.push('/dashboard')}
        style={{ cursor: 'pointer', paddingLeft: 16 }}
      />
      <Box flexGrow={1} />
      <IconButton
        style={{ marginRight: 10 }}
        onClick={() => history.push('/notifications')}
      >
        <Badge badgeContent={auth.NotificationCount} color="secondary">
          <NotificationsIcon style={{ color: '#ffffff' }} />
        </Badge>
      </IconButton>
      <SwipeableTemporaryDrawer />
    </>
  );
}
