//UAT SERVER
const url = "http://182.72.203.244:4023";
const account = `${url}/account`;
const auth = `${url}/auth`;
const notification = `ws://182.72.203.244:4024/notification`;
export const websiteULR = "http://logistic-construction.mobiloitte.org";

//LIVE SERVER

// const url = "https://java.porteight.io";
// const account = `${url}/account`;
// const auth = `${url}/auth`;
// const notification = `wss://notification.porteight.io/notification`;
// export const websiteULR = "https://porteight.io";

const ApiConfig = {
  auth: auth,
  getState: `${account}/get-state-country-wise`,
  getCity: `${account}/get-cities-state-wise`,
  signUp: `${account}/signup`,
  myAccount: `${account}/logistic/my-account`,
  getUserProfileDetails: `${account}/logistic/get-user-profile-details`,
  forgetPassword: `${account}/forget-password`,
  resetPassword: `${account}/reset-password`,

  getCompanyeBookingList: `${account}/logistic/get-quote-booking-list`,
  getCompanyeQuoteList: `${account}/logistic/get-quote-request-list`,

  getFleetBidsList: `${account}/logistic/get-quote-request-list-form-supplier`,
  getFleetBidsListDetails: `${account}/logistic/view-supplier-request-detail`,
  AcceptBidsConfirmedApi: `${account}/logistic/accept-poIssued-request-by-fleet`,
  SendBidsQuoteApi: `${account}/logistic/set-bid-amount-for-request-by-fleet`,
  RejectBidsQuoteApi: `${account}/logistic/cancel-supplier-quote-request-by-fleet`,
  getDriverListApi: `${account}/logistic/get-driver-for-dropdown`,
  getTruckNumberListApi: `${account}/logistic/get-truck-for-dropdown`,
  getTruckTypeListApi: `${account}/logistic/get-truck-type-for-dropdown`,
  addDriverbyfleet: `${account}/logistic/add-Driver-by-fleet`,
  deleteDriverByFleet: `${account}/logistic/delete-driver-by-fleet`,
  getAllDriverListApi: `${account}/logistic/filter-Driver-details-by-fleet`,
  assignTruckByFleet: `${account}/logistic/assign-truck-by fleet`,
  getFleetBookingList: `${account}/logistic/get-quote-booking-list-for-fleet`,
  getFleetBookingListDetails: `${account}/logistic/view-quote-booking-fleet`,
  getFleetTrucksList: `${account}/logistic/filter-tuck-details-by-fleet`,
  addTruckByFleet: `${account}/logistic/add-truck-by-fleet`,
  cancelTruckByFleet: `${account}/logistic/delete-truck-by-fleet`,
  getFleetPaymentList: `${account}/logistic/get-quote-payment-list-for-fleet`,
  getFleetPaymentListDetails: `${account}/logistic/view-quote-Payment-fleet`,
  getUnitList: `${account}/logistic/get-unit-list`,

  getCompanyePaymentList: `${account}/logistic/get-quote-payment-list`,
  getCompanyeQuoteListDetails: `${account}/logistic/view-quote-request-by-id`,
  getCompanyeBookingListDetails: `${account}/logistic/view-quote-booking`,
  getCompanyePaymentListDetails: `${account}/logistic/view-quote-Payment`,
  getCompanyeSiteLocationList: `${account}/logistic/get-site-location-list`,
  getCompanyeMaterilList: `${account}/get-material-list`,
  getCompanyeAddQuoteRequest: `${account}/logistic/add-quote-request`,
  getCompanyesupplierList: `${account}/logistic/get-supplier-list`,
  uploadFile: `${account}/upload-file`,
  addSiteByClient: `${account}/logistic/add-SiteBy-client`,
  changePassword: `${account}/logistic/change-password`,
  getStaticContent: `${account}/logistic/get-static-content`,
  getStaticContentUnAuth: `${account}/get-static-content`,
  updateUserProfileDetails: `${account}/logistic/update-user-profile-details`,
  addUser: `${account}/logistic/add-user`,
  companyDashboardBookingCount: `${account}/logistic/count-booking`,
  fleetDashboardBookingCount: `${account}/logistic/count-booking-fleet`,
  companyDashboardInvoiceCount: `${account}/logistic/count-invoice`,
  fleetDashboardInvoiceCount: `${account}/logistic/count-invoice-pending-fleet`,
  companyDashboardOpenQuoteCount: `${account}/logistic/count-open-quotes`,
  fleetDashboardOpenQuoteCount: `${account}/logistic/count-open-quotes-fleet`,
  companyDashboardQuoteRequestCount: `${account}/logistic/get-total-quote-request-client`,
  companyDashboardPendingQuoteRequest: `${account}/logistic/get-pending-quote-request-client`,
  companyDashboardPieChart: `${account}/logistic/chart-material-wise`,
  supplierDashboardPieChart: `${account}/logistic/company-chart-material--wise`,
  transpoterDashboardPieChart: `${account}/logistic/fleet-chart-material--wise`,
  fleetDashboardPieChart: `${account}/logistic/fleet-chart-material--wise`,
  supplierDashboardBookingCount: `${account}/logistic/count-total-company-booking-supplier`,
  supplierDashboardInvoiceCount: `${account}/logistic/get-count-for-pending-invoice-company`,
  supplierDashboardOpenQuoteCount: `${account}/logistic/count-total-company-quotes-supplier`,
  transpoterDashboardBookingCount: `${account}/logistic/count-total-fleet-booking-supplier`,
  transpoterDashboardInvoiceCount: `${account}/logistic/get-count-for-pending-invoice-fleet`,
  transpoterDashboardOpenQuoteCount: `${account}/logistic/count-total-fleet-quotes-supplier`,
  paymentGraph: `${account}/logistic/payment-graph`,
  supplierPaymentGraph: `${account}/logistic/company-payment-graph`,
  fleetPaymentGraph: `${account}/logistic/payment-graph-fleet`,
  transpoterPaymentGraph: `${account}/logistic/fleet-payment-graph`,
  getthermalPlantsData: `${account}/logistic/get-thermalPlants`,
  acceptBidRequestForQuote: `${account}/logistic/accept-bid-request-for-quote`,
  acceptBidRequestForFleet: `${account}/logistic/accept-bid-request-for-fleet`,
  cancelBidRequestForQuote: `${account}/logistic/cancel-quote-request`,
  cancelBidRequestForQuoteSupplier: `${account}/logistic/cancel-quote-request-supplier`,
  paidByClient: `${account}/logistic/paid-by-client`,
  paidByFleet: `${account}/logistic/received-by-fleet-payment`,
  getQuoteRequestListFromCompany: `${account}/logistic/get-quote-request-list-from-company`,
  viewQuoteRequestSupplierById: `${account}/logistic/view-quote-request-supplier-by-id`,
  viewClientBidBooking: `${account}/logistic/view-bid-booking`,
  getClientBidRequestDetail: `${account}/logistic/view-supplier-bid-request-by-id`,
  getQuoteRequestDetail: `${account}/logistic/get-quote-request-detail`,
  getQuoteBookingListForCompany: `${account}/logistic/get-quote-booking-list-for-company`,
  cancelQuoteCompanyRequest: `${account}/logistic/cancel-client-quote-request-by-supplier`,
  setBidAmountForQuote: `${account}/logistic/set-bid-amount-for-client-quote`,
  acceptPoIssuedRequest: `${account}/logistic/accept-poIssued-request`,
  deleteRoute: `${account}/logistic/delete-route`,
  getFleetList: `${account}/logistic/get-fleet-list`,
  getTruckTypeList: `${account}/logistic/get-truck-type-list`,
  addSupplierRequestToTransporter: `${account}/logistic/add-supplier-request-to-transporter`,
  getQuotePaymentListForCompany: `${account}/logistic/get-quote-payment-list-for-company`,
  sendInvoiceSupplierToClient: `${account}/logistic/send-invoice-supplier-to-client`,
  sendInvoiceFleetToSupplier: `${account}/logistic/send-invoice-fleet-to-supplier`,

  sendInvoiceSupplierToClientByFleetassignId: `${account}/logistic/send-invoice-supplier-to-client-by-fleetassignId`,
  sendInvoiceFleetToSupplierByFleetassignId: `${account}/logistic/send-invoice-fleet-to-supplier-by-fleetassignId`,
  receivedByFleetPaymentByInvoiceFleetId: `${account}/logistic/received-by-fleet-payment--by-invoiceFleetId`,
  paidBySupplierByInvoiceFleetid: `${account}/logistic/paid-by-supplier-by-invoiceFleetid`,
  shortCloseBySupplier: `${account}/logistic/short-close-by-supplier`,

  livelocation: `${account}/live-location`,
  viewClientBigPayment: `${account}/logistic/view-bid-Payment`,
  paidByClientSupplier: `${account}/logistic/received-by-supplier-payment`,
  getQuoteBookingListForSupplier: `${account}/logistic/get-quote-booking-list-for-supplier`,
  getQuoteRequestListForSupplier: `${account}/logistic/get-quote-request-list-for-supplier`,
  viewQuoteBookingSupplier: `${account}/logistic/view-quote-booking-supplier`,
  getQuotePaymentListForSupplier: `${account}/logistic/get-quote-payment-list-for-supplier`,
  viewQuotePaymentSupplier: `${account}/logistic/view-quote-Payment-supplier`,
  paidBySupplier: `${account}/logistic/paid-by-supplier`,
  getNotificationData: `${notification}/get-notification-data`,
  readNotification: `${notification}/read-notification`,
  deleteNotificationDataById: `${notification}/delete-notification-data-by-id`,
  deleteAllNotificationData: `${notification}/delete-all-notification-data`,
  realTimeFectLiveLocation: `${notification}/real-time-fect-live-location`,

  shortColseByCompany: `${account}/logistic/short-close-by-company`,

  getContactDetails: `${account}/get-contact-Details`,
};

export default ApiConfig;
