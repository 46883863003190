import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
// import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";
import AuthGuard from "src/component/AuthGuard";
import {
  CompanyUserGuard,
  CompanyGuard,
  SupplierGuard,
  FleetGuard,
} from "src/component/CompanyUserGuard";
export const routes = [
  {
    exact: true,
    path: "/",
    component: lazy(() => import("src/views/auth/Main")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },
  {
    exact: true,
    path: "/notifications",
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import("src/views/pages/Bids/notification")),
  },
  {
    exact: true,
    path: "/quote-request-form",
    layout: DashboardLayout,
    guard: CompanyGuard,
    component: lazy(() => import("src/views/pages/Bids/QuoteRequestForm")),
  },
  // {
  //   exact: true,
  //   path: "/status-details-cancel",
  //   layout: DashboardLayout,
  //   guard: AuthGuard,
  //   component: lazy(() => import("src/views/pages/Bids/status-details-cancel")),
  // },
  {
    exact: true,
    path: "/quote-details",
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() =>
      import("src/views/pages/Bids/Compnay/Quote/QuoteDetails")
    ),
  },
  // {
  //   exact: true,
  //   path: "/bid-details",
  //   layout: DashboardLayout,
  //   guard: AuthGuard,
  //   component: lazy(() =>
  //     import("@/views/pages/Bids/Supplier/Compnay/BidsAndQuotes/Bids/BidsDetails")
  //   ),
  // },
  {
    exact: true,
    path: "/booking-details",
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() =>
      import("src/views/pages/Bids/Compnay/Bookings/BookingDetails")
    ),
  },
  {
    exact: true,
    path: "/billing-details",
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() =>
      import("src/views/pages/Bids/Compnay/Payment/PaymentDetails")
    ),
  },
  {
    exact: true,
    path: "/fleet-billing-details",
    layout: DashboardLayout,
    guard: FleetGuard,
    component: lazy(() =>
      import("src/views/pages/Bids/Fleet/Payment/PaymentDetails")
    ),
  },
  // {
  //   exact: true,
  //   path: "/bids-assign-save",
  //   layout: DashboardLayout,
  //   guard: AuthGuard,
  //   component: lazy(() => import("src/views/pages/Bids/bids-assign-save")),
  // },
  {
    exact: true,
    path: "/bids-list",
    layout: DashboardLayout,
    guard: FleetGuard,
    component: lazy(() => import("src/views/pages/Bids/Fleet/Bids/BidsList")),
  },
  {
    exact: true,
    path: "/bids-details",
    layout: DashboardLayout,
    guard: FleetGuard,
    component: lazy(() =>
      import("src/views/pages/Bids/Fleet/Bids/BidsDetails")
    ),
  },
  {
    exact: true,
    path: "/supplier-bid-accept",
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import("src/views/pages/Bids/SupplierBidAccept")),
  },
  {
    exact: true,
    path: "/quote-list-client",
    layout: DashboardLayout,
    guard: CompanyGuard,
    component: lazy(() =>
      import("src/views/pages/Bids/Compnay/Quote/QuoteList")
    ),
  },
  // {
  //   exact: true,
  //   path: "/bids-list",
  //   layout: DashboardLayout,
  //   guard: CompanyGuard,
  //   component: lazy(() =>
  //     import("src/views/pages/Bids/Fleet/Bids/BidsList")
  //   ),
  // },
  {
    exact: true,
    path: "/supplier/quotes-and-bids",
    layout: DashboardLayout,
    guard: SupplierGuard,
    component: lazy(() =>
      import("src/views/pages/Bids/Supplier/BidsAndQuotes/index")
    ),
  },
  {
    exact: true,
    path: "/supplier/bookings",
    layout: DashboardLayout,
    guard: SupplierGuard,
    component: lazy(() =>
      import("src/views/pages/Bids/Supplier/Bookings/index")
    ),
  },
  {
    exact: true,
    path: "/supplier/billing",
    layout: DashboardLayout,
    guard: SupplierGuard,
    component: lazy(() =>
      import("src/views/pages/Bids/Supplier/Payments/index")
    ),
  },
  {
    exact: true,
    path: "/supplier/active-quote-details",
    layout: DashboardLayout,
    guard: SupplierGuard,
    component: lazy(() =>
      import(
        "src/views/pages/Bids/Supplier/BidsAndQuotes/Quote/ActiveQuoteDetails"
      )
    ),
  },
  {
    exact: true,
    path: "/supplier/quote-details",
    layout: DashboardLayout,
    guard: SupplierGuard,
    component: lazy(() =>
      import("src/views/pages/Bids/Supplier/BidsAndQuotes/Quote/QuoteDetails")
    ),
  },
  {
    exact: true,
    path: "/supplier/bookings-details-company",
    layout: DashboardLayout,
    guard: SupplierGuard,
    component: lazy(() =>
      import("src/views/pages/Bids/Supplier/Bookings/Company/BookingDetails")
    ),
  },
  {
    exact: true,
    path: "/supplier/bookings-details-fleet",
    layout: DashboardLayout,
    guard: SupplierGuard,
    component: lazy(() =>
      import("src/views/pages/Bids/Supplier/Bookings/Fleet/BookingDetails")
    ),
  },
  {
    exact: true,
    path: "/supplier/billing-details-company",
    layout: DashboardLayout,
    guard: SupplierGuard,
    component: lazy(() =>
      import("src/views/pages/Bids/Supplier/Payments/Company/PaymentDetails")
    ),
  },
  {
    exact: true,
    path: "/supplier/billing-details-fleet",
    layout: DashboardLayout,
    guard: SupplierGuard,
    component: lazy(() =>
      import("src/views/pages/Bids/Supplier/Payments/Fleet/PaymentDetails")
    ),
  },
  {
    exact: true,
    path: "/supplier/bids-details",
    layout: DashboardLayout,
    guard: SupplierGuard,
    component: lazy(() =>
      import("src/views/pages/Bids/Supplier/BidsAndQuotes/Bids/BidsDetails")
    ),
  },
  {
    exact: true,
    path: "/drivers-list",
    layout: DashboardLayout,
    guard: FleetGuard,
    component: lazy(() => import("src/views/pages/Bids/driverslist")),
  },
  {
    exact: true,
    path: "/bookings-list-client",
    layout: DashboardLayout,
    guard: CompanyGuard,
    component: lazy(() =>
      import("src/views/pages/Bids/Compnay/Bookings/Mybookings")
    ),
  },
  {
    exact: true,
    path: "/billing-list-fleet",
    layout: DashboardLayout,
    guard: FleetGuard,
    component: lazy(() =>
      import("src/views/pages/Bids/Fleet/Payment/PaymentListClient")
    ),

    // component: lazy(() => import("src/views/pages/Bids/PaymentListFleet")),
  },
  {
    exact: true,
    path: "/billing-list-client",
    layout: DashboardLayout,
    guard: CompanyGuard,
    component: lazy(() =>
      import("src/views/pages/Bids/Compnay/Payment/PaymentListClient")
    ),
  },
  {
    exact: true,
    path: "/truck-list",
    layout: DashboardLayout,
    guard: FleetGuard,
    component: lazy(() => import("src/views/pages/Bids/TruckList")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import("src/views/pages/Bids/ChangePassword")),
  },
  {
    exact: true,
    path: "/contactus",
    layout: LoginLayout,
    // guard: AuthGuard,
    component: lazy(() => import("src/views/pages/Bids/ContactUs")),
  },
  {
    exact: true,
    path: "/add-account",
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import("src/views/pages/Bids/AddAccount")),
  },
  {
    exact: true,
    path: "/add-truck",
    layout: DashboardLayout,
    guard: FleetGuard,
    component: lazy(() => import("src/views/pages/Bids/AddTruck")),
  },
  {
    exact: true,
    path: "/add-driver",
    layout: DashboardLayout,
    guard: FleetGuard,
    component: lazy(() => import("src/views/pages/Bids/AddDriver")),
  },
  {
    exact: true,
    path: "/mybooking",
    layout: DashboardLayout,
    guard: FleetGuard,
    component: lazy(() =>
      import("src/views/pages/Bids/Fleet/Bookings/Mybookings")
    ),

    // component: lazy(() => import("src/views/pages/Bids/myBooking")),
  },
  {
    exact: true,
    path: "/booking-list-details",
    layout: DashboardLayout,
    guard: FleetGuard,
    component: lazy(() =>
      import("src/views/pages/Bids/Fleet/Bookings/BookingDetails")
    ),
  },
  // {
  //   exact: true,
  //   path: "/mybookings",
  //   layout: HomeLayout,
  //   component: lazy(() => import("@/views/pages/Bids/my-bookingss")),
  // },
  {
    exact: true,
    path: "/quote-request",
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import("src/views/pages/Bids/QuoteRequest")),
  },
  // {
  //   exact: true,
  //   path: "/listdetails",
  //   layout: DashboardLayout,
  //   guard: AuthGuard,
  //   component: lazy(() => import("src/views/pages/Bids/listdetails")),
  // },
  {
    exact: true,
    path: "/active-quote-details",
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() =>
      import("src/views/pages/Bids/Compnay/Quote/ActiveQuoteDetails")
    ),
  },
  {
    exact: true,
    path: "/list-activethermal",
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import("src/views/pages/Bids/ListOfActiveThermal")),
  },
  {
    exact: true,
    path: "/crusher-mining-info",
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import("src/views/pages/Bids/CrusherMiningInfo")),
  },
  {
    exact: true,
    path: "/trucksmap",
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import("src/views/pages/Bids/trucksmap")),
  },
  // {
  //   exact: true,
  //   path: "/quotelistListDetails",
  //   layout: DashboardLayout,
  //   guard: AuthGuard,
  //   component: lazy(() => import("@/views/pages/Bids/Compnay/Quote/QuoteList")),
  // },
  // {
  //   exact: true,
  //   path: "/status-details",
  //   layout: DashboardLayout,
  //   guard: AuthGuard,
  //   component: lazy(() => import("src/views/pages/Bids/status-details")),
  // },
  {
    exact: true,
    path: "/new-site",
    layout: DashboardLayout,
    guard: CompanyUserGuard,
    component: lazy(() => import("src/views/pages/Bids/NewSite")),
  },
  // {
  //   exact: true,
  //   path: "/confirmscreen",
  //   layout: DashboardLayout,
  //   guard: AuthGuard,
  //   component: lazy(() => import("src/views/pages/Bids/confirmscreen")),
  // },
  // {
  //   exact: true,
  //   path: "/truckslist",
  //   layout: DashboardLayout,
  //   guard: FleetGuard,
  //   component: lazy(() => import("src/views/pages/Bids/truckslist")),
  // },
  {
    exact: true,
    path: "/create-user",
    layout: DashboardLayout,
    guard: CompanyUserGuard,
    component: lazy(() => import("src/views/pages/Bids/CreateUser")),
  },
  {
    exact: true,
    path: "/about-us",
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import("src/views/pages/Bids/AboutUs")),
  },
  {
    exact: true,
    path: "/terms-conditions",
    layout: LoginLayout,
    // guard: AuthGuard,
    component: lazy(() => import("src/views/pages/Bids/TermsCondition")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: LoginLayout,
    // guard: AuthGuard,
    component: lazy(() => import("src/views/pages/Bids/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/crushers-mine",
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import("src/views/pages/Bids/CrushersMin")),
  },
  {
    exact: true,
    path: "/bid-request",
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() =>
      import("src/views/pages/Bids/Supplier/BidsAndQuotes/Bids/BidRequest")
    ),
  },
  {
    exact: true,
    path: "/assign",
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import("src/views/pages/Bids/Assign")),
  },
  {
    exact: true,
    path: "/my-profile",
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import("src/views/pages/Bids/MyProfile")),
  },
  {
    exact: true,
    path: "/signup",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Login/signup")),
  },
  {
    exact: true,
    path: "/forgot-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Login/ForgotPassword")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Login/ResetPassword")),
  },
  {
    exact: true,
    path: "/dashboard",
    // guard:true,
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to='/404' />,
  },
];
